import { Outlet } from 'react-router-dom';
import {Layout as DashboardLayout, Layout as AppLayout} from 'src/layouts/appLayout';
import Error401Page from 'src/pages/401';
import Error404Page from 'src/pages/404';
import Error500Page from 'src/pages/500';
import { authRoutes } from './auth';
import {lazy, Suspense} from "react";
import { Layout as AuthLayout } from 'src/layouts/auth/classic-layout';
import {paths} from "../paths";
import HomePage from 'src/pages';

const IndexPage = lazy(() => import('src/pages/claimList/index'));
const ClaimCreate = lazy(() => import('src/pages/claimList/claim/create'));
const ClaimEdit = lazy(() => import('src/pages/claimList/claim/[caseID]/edit'));

const MonitorPage = lazy(() => import('src/pages/monitor/index'));
const SurveyorListPage = lazy(() => import('src/pages/surveyorList/index'));

const ReportPage = lazy(() => import('src/pages/report/index'));
const TimeSurveyReportPage = lazy(() => import('src/pages/report/timeSurvey-report'));
const AccidentReportPage = lazy(() => import('src/pages/report/accident-report'));
const LogbookReportPage = lazy(() => import('src/pages/report/logbook-report'));
const SeriousReportPage = lazy(() => import('src/pages/report/serious-report'));
const LogSurveyorLoginPage = lazy(() => import('src/pages/report/log-surveyor-login'));

const PageChangePassword = lazy(() => import('src/pages/auth/appAuth/page-changePassword'));

const UserManagementPage =  lazy(() => import('src/pages/management/user'));
const SurveyorManagementPage =  lazy(() => import('src/pages/management/surveyor'));
const RoleManagementPage =  lazy(() => import('src/pages/management/role'));


export const routes = [
  {
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        index: true,
        element: <HomePage />
      },
    ]
  },
  {
    path: 'claimList',
    element: (
        <AppLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AppLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />
      },
      {
        path: 'create',
        element: <ClaimCreate />
      },
      {
        path: ':caseID/edit',
        element: <ClaimEdit />
      },
    ]
  },
  {
    path: 'monitor',
    element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <MonitorPage />
      },
    ]
  },
  {
    path: 'surveyors',
    element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <SurveyorListPage />
      },
    ]
  },
  {
    path: 'report',
    element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
    ),
    children: [
      {
        path: paths.report.timeSurvey,
        element: <TimeSurveyReportPage/>
      },
      {
        path: paths.report.accident,
        element: <AccidentReportPage/>
      },
      {
        path: paths.report.logbook,
        element: <LogbookReportPage/>
      },
      {
        path: paths.report.serious,
        element: <SeriousReportPage/>
      },
      {
        path: paths.report.logSurveyorLogin,
        element: <LogSurveyorLoginPage/>
      },

    ]
  },
  {
    path: 'management',
    element: (
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
    ),
    children: [
      {
        path: paths.management.userManagement,
        element: <UserManagementPage/>
      },
      {
        path: paths.management.surveyorManagement,
        element: <SurveyorManagementPage/>
      },
      {
        path: paths.management.roleManagement,
        element: <RoleManagementPage/>
      },
    ]
  },

  ...authRoutes,

  {
    path: 'changePassword',
    element: <AuthLayout><PageChangePassword /></AuthLayout>
  },
  {
    path: '401',
    element: <Error401Page />
  },
  {
    path: '404',
    element: <Error404Page />
  },
  {
    path: '500',
    element: <Error500Page />
  },
  {
    path: '*',
    element: <Error404Page />
  }
];
