import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupsIcon from '@mui/icons-material/Groups';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import authService from "../../api/auth/authService";

export const useSections = () => {
  const { t } = useTranslation();
  let userRole = authService.getProfile()?.role

  const isMenuVisible = (key) => {
    return userRole && userRole[key] === 1;
  }
  const getSubMenuReport = () => {
    if (isMenuVisible('menu_report')){
      const sub_menu_report =[
        {
          key:"get_report_time_survey",
          title: 'Time Survey Report',
          path: paths.report.timeSurvey
        },
        {
          key:"get_report_accident",
          title: 'Accident Report',
          path: paths.report.accident
        },
        {
          key:"get_report_look_book",
          title: 'Logbook Report',
          path: paths.report.logbook
        },
        {
          key:"get_report_serious",
          title: 'รายงาน งานร้ายแรง',
          path: paths.report.serious
        },
        {
          key:"get_report_log_surveyor_login",
          title: 'Log Surveyor Login',
          path: paths.report.logSurveyorLogin
        }
      ];

      let list_visible_subMenu = [];
      sub_menu_report.forEach((item_sub_report) => {
        if (isMenuVisible(item_sub_report.key)) {
          list_visible_subMenu.push(item_sub_report);
        }
      });
      return list_visible_subMenu;
    }
    return [];
  };

  return useMemo(() => {
    const sections = [
      {
        items: isMenuVisible('menu_callcenter') ? [
          {
            title: t(tokens.nav.claimList),
            path: paths.claimList.index,
            icon: <FormatListBulletedIcon />,
            key:'menu_callcenter'
          },
        ] : []
      },
      {
        items: isMenuVisible('menu_monitor') ? [
          {
            title: t(tokens.nav.monitor),
            path: paths.monitor.index,
            icon: <MyLocationIcon />,
            key:'menu_monitor'
          },
        ] : []
      },
      {
        items: isMenuVisible('menu_surveyorOnline') ? [
          {
            title: t(tokens.nav.surveyors),
            path: paths.surveyors.index,
            icon: <GroupsIcon />,
            key:'menu_surveyorOnline'
          },
        ] : []
      },
      {
        items: isMenuVisible('menu_report') ? [
          {
            title: t(tokens.nav.report),
            path: paths.report.index,
            icon: <SummarizeIcon />,
            key: 'menu_report',
            items: getSubMenuReport()
          },
        ] : []
      },
      {
        items: isMenuVisible('menu_system') ? [
          {
            title: 'Management',
            path: paths.management.index,
            icon: <ManageAccountsIcon />,
            key:'menu_system',
            items: [
              {
                title: 'Surveyor',
                path: paths.management.surveyorManagement
              },
              {
                title: 'User',
                path: paths.management.userManagement
              },
              {
                title: 'Role',
                path: paths.management.roleManagement
              },
            ]
          },
        ] : []
      },
    ];
    return sections.filter(section => section.items.length > 0);
  }, [t, userRole]);
};
