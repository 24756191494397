export const paths = {
  index: '/',
  auth: {
    appAuth: {
      login: '/auth/appAuth/login',

    },
  },
  changePassword: '/changePassword',
  monitor:{
    index:'/monitor',
  },
  surveyors:{
    index:'/surveyors',
  },
  report:{
    index:'/report',
    timeSurvey:'/report/timeSurvey',
    accident:'/report/accident',
    logbook:'/report/logbook',
    serious:'/report/serious',
    logSurveyorLogin:'/report/logSurveyorLogin',
  },
  claimList:{
    index:'/claimList',
    claimCreate: '/claimList/create',
    claimEdit: '/claimList/:caseID/edit',
  },
  management:{
    index:'/management',
    userManagement: '/management/user',
    surveyorManagement: '/management/surveyor',
    roleManagement: '/management/role',
  },
  claimService:{
    callCenter:{
      index:'/claimService/callCenter',
      claimDetails: '/claimService/callCenter/:caseID',
      claimCreate: '/claimService/callCenter/create',
      claimEdit: '/claimService/callCenter/:caseID/edit',
    }
  },

  401: '/401',
  404: '/404',
  500: '/500'
};
